import React, { useEffect, useState, useRef } from "react";
import { Table, Row, Col, Input, Space, Button } from "antd";
import axios from "axios";
import moment from "moment";
import useTableSearch from "../../../common/hooks/useTableSearch";

const GRNSummary = () => {
  const [data, setData] = useState([]);
  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    listAPICall();
  }, []);

  const listAPICall = async () => {
    try {
      // const { data } = await axios.get(`/api/purchase/grn/list`);
      const { data } = await axios.get(`/api/purchase/grn/list`);
      const allMaterials = await axios.get(`/api/goods/material/list`);

      setMaterials(allMaterials.data);

      let _data = data.map((el) => {
        return {
          ...el,
          material_name:
            allMaterials?.data?.find((_mat) => _mat.id === el.material_id)
              ?.name || "Not found",
        };
      });
      setData(_data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const columns = [
    {
      title: "GRN #",
      dataIndex: "id",
      ...useTableSearch("id"),
    },
    { title: "PO #", dataIndex: "po_id", ...useTableSearch("po_id") },
    {
      title: "Material Name",
      // dataIndex: "material_id",
      dataIndex: "material_name",
      ...useTableSearch("material_name"),
      // render: text => {
      //   return materials.find(el => el.id === text).name;
      // }
    },

    {
      title: "Date",
      dataIndex: "date",
      defaultSortOrder: "descend",
      sorter: (x, y) => moment(x.date).valueOf() - moment(y.date).valueOf(),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (text, row) => text + " " + row.uom,
    },
    // { title: "UoM", dataIndex: "uom" },
    { title: "Rate per unit", dataIndex: "rate" },
    { title: "Tax (%)", dataIndex: "tax" },
    { title: "Amount", dataIndex: "amount" },
  ];

  return (
    <>
      <Row gutter={16}>
        <Col span={4} offset={22}></Col>
      </Row>
      <br />
      <Table columns={columns} dataSource={data} />
    </>
  );
};

export default GRNSummary;
