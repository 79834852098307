import React, { useEffect } from "react";
import { Tabs } from "antd";
import ReportForPaymentTerms from "./reports.paymentTerms";
import ReportByOrganization from "./reports.ngoWise";
import { connect } from "react-redux";
const { TabPane } = Tabs;

const Report = ({ organization }) => {
  return (
    <div>
      <Tabs destroyInactiveTabPane={true} defaultActiveKey="1">
        <TabPane tab={<span>Payment Terms</span>} key="1">
          <ReportForPaymentTerms />
        </TabPane>

        {organization[0]._id === "621346f29ad2ff6d4fecb6b2" && (
          <TabPane tab={<span>NGO wise</span>} key="2">
            <ReportByOrganization />
          </TabPane>
        )}
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    organization: state.auth?.user.organizations,
  };
};

export default connect(mapStateToProps)(Report);
