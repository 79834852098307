import React from "react";
import { Text, Page, View, Image, Document } from "@react-pdf/renderer";
import { styles, comb } from "../../styles";

import numberToWords from "../../../../common/functions/numberToWords";

import moment from "moment";
import { PAYMENT_TERMS } from "../../../../assets/constants";
import taruLogo from "../../../../assets/images/taruLogo.png";

import commaSeparatedRupees from "../../../../common/functions/commaSeparatedRupees";
import minutesToDuration from "../../../../common/functions/minutesToDuration";
import getAddress from "../../../../common/functions/getAddress";

const MakeOrderInvoice = ({ data }) => {
  const tablesReturner = () => {
    const len = data.items.length;
    const ITEMS_PER_PAGE = 6;
    const MAX_ITEMS_IN_OTHER_PAGES = 15;

    if (len <= ITEMS_PER_PAGE)
      return (
        <Table
          mo_details={data.mo_details}
          material_schedule={data.material_schedule}
          items={data.items}
        />
      );
    else {
      let _insertedInPage = 0;

      let _firstSix = (
        <>
          <Table items={data.items.slice(0, 6)} />
          <View break />
        </>
      );
      let _combinedArr = [_firstSix];
      for (let i = 6; i < len; i = i + MAX_ITEMS_IN_OTHER_PAGES) {
        let _items = data.items.slice(i, i + MAX_ITEMS_IN_OTHER_PAGES);
        _insertedInPage += _items.length;
        _combinedArr.push(
          <>
            <Table items={_items} />
            {_insertedInPage >= MAX_ITEMS_IN_OTHER_PAGES && <View break />}
          </>
        );
        if (_insertedInPage >= MAX_ITEMS_IN_OTHER_PAGES) _insertedInPage = 0;
      }
      return _combinedArr;
    }
  };

  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <Text
            style={[
              styles.upperCase,
              styles.bold,
              {
                marginBottom: 10,
                fontSize: 20,
                textAlign: "center",
                color: "darkgreen",
              },
            ]}
          >
            Make Order
          </Text>

          <InvoiceHeader moDetails={data.mo_details} headerInfo={data.header} />

          <OtherOrderDetails data={data.mo_details} />
          {/*
          <SourceDestination
            consignee={data.consignee}
            customer={data.customer}
          />
          <TermsOfInvoice
            moDetails={data.mo_details}
            transporter={data.transporter}
          /> */}

          <View>
            <Text style={{ color: "black" }}>{data?.due_date}</Text>
          </View>

          {/* <Table /> */}
          <View>
            <Text style={{ marginBottom: -8 }}>Material Schedule</Text>
            {tablesReturner()}
          </View>

          <LabourSchedule data={data?.labour_schedule} />

          <CommentsAndBreakUp items={data.items} moDetails={data.mo_details} />

          <ClosingNotes />
        </Page>
      </Document>
    </>
  );
};

const InvoiceHeader = ({ moDetails, headerInfo }) => {
  const { id, created_date } = moDetails || "";
  const { GST, address, city, email, logo, name, pincode, state } = headerInfo;

  return (
    <>
      <View style={[styles.flex, styles.justifyBetween, { marginBottom: 10 }]}>
        {/* Top Left */}
        <View
          style={[
            styles.flexCol,
            styles.fontZeroPointNineEm,
            { maxWidth: 300 },
          ]}
        >
          <Text style={[styles.bold, styles.upperCase, { fontSize: 12 }]}>
            {name}
          </Text>
          <Text>{address}</Text>
          <Text>
            {state}, {pincode}
          </Text>
          {/* GSTIN */}
          <Text style={{ paddingTop: 5 }}>
            <Text style={styles.bold}>GSTIN / UIN: </Text>
            <Text>{GST}</Text>
          </Text>
          {/* State Name */}
          <Text>
            <Text style={styles.bold}>State Name: </Text>
            <Text>{state}</Text>
          </Text>
          {/* Email */}
          <Text>
            <Text style={styles.bold}>Email: </Text>
            <Text style={{ color: "darkblue" }}>{email}</Text>
          </Text>
        </View>

        {/* Top Right */}

        <View style={styles.flexCol}>
          <Image
            src={headerInfo?.logo}
            style={{
              width: 60,
              height: 30,
              margin: "auto",
              marginBottom: 10,
            }}
          />
          <Text>
            <Text style={styles.bold}>Creation Date:</Text>
            <Text>{moment(created_date).format("Do MMMM YYYY")}</Text>
          </Text>
          <Text>
            <Text style={styles.bold}>Print Date:</Text>
            <Text>{moment().format("Do MMMM YYYY")}</Text>
          </Text>
          <Text>
            <Text style={styles.bold}>MO #: </Text>
            <Text>{id}</Text>
          </Text>
        </View>
      </View>
      <View
        style={{
          height: 1,
          backgroundColor: "grey",
          width: "100%",
          marginBottom: 20,
        }}
      />
    </>
  );
};

const OtherOrderDetails = ({ data }) => {
  const {
    created_by,
    created_date,
    approved_by,
    approved_date,
    due_date,
    make_status,
    fill_status,
    order_status,
  } = data || "";
  return (
    <View style={[styles.flex, { marginBottom: 10 }]}>
      <FromToText heading="Order Status" mainBody={order_status} />

      <FromToText
        heading="Required by Date"
        mainBody={moment(due_date).format("Do MMMM YYYY")}
      />

      <FromToText heading="Make Status" mainBody={make_status} />

      <FromToText heading="Fill Status" mainBody={fill_status} />
    </View>
  );
};

const SourceDestination = ({ consignee, customer }) => {
  const {
    name,
    contact,
    gstin,
    email,
    address1,
    address2,
    city,
    state,
    pincode,
    country,
  } = consignee || "";
  return (
    <View style={[styles.flex, { marginBottom: 10 }]}>
      <View style={[comb.sourceDestBox, { marginRight: 10 }]}>
        <Text style={comb.fromToText}>Consignee (Ship To)</Text>
        <View style={[styles.paddingFive, styles.flexCol]}>
          <View>
            <Text style={[styles.bold, styles.upperCase]}>{name}</Text>
          </View>
          {/* first address */}
          <View>
            <Text>
              {getAddress({ address1, address2, city, pincode })}
              {/* {address1}, {address2}, {city}, {pincode} */}
            </Text>
          </View>
          {/* GSTIN/UIN */}
          <View>
            <Text>
              <Text style={styles.bold}>GSTIN/UIN </Text>: {gstin}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>State Name</Text>: {state} ({country})
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>email</Text>: {email}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>Phone</Text>: {contact}
            </Text>
          </View>
        </View>
      </View>

      <View style={[comb.sourceDestBox]}>
        <Text style={comb.fromToText}>Buyer (Bill To)</Text>
        <View style={[styles.paddingFive, styles.flexCol]}>
          <View>
            <Text style={[styles.bold, styles.upperCase]}>
              {customer?.name}
            </Text>
          </View>
          {/* first address */}
          <View>
            <Text>{getAddress(customer)}</Text>
          </View>
          {/* GSTIN/UIN */}
          <View>
            <Text>
              <Text style={styles.bold}>GSTIN/UIN </Text>: {customer?.gstin}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>State Name</Text>: {customer?.state} (
              {customer?.country})
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>email</Text>: {customer?.email}
            </Text>
          </View>

          <View>
            <Text>
              <Text style={styles.bold}>Phone</Text>: {customer?.contact}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const FromToText = ({ heading, mainBody, children }) => {
  return (
    <View style={[comb.sourceDestBox, { marginRight: 3 }]}>
      <Text style={[comb.fromToText, comb.termsText]}>{heading}</Text>

      <View style={styles.paddingFive}>
        <Text>{mainBody}</Text>
        {children}
      </View>
    </View>
  );
};

const Table = ({ items, material_schedule, mo_details }) => {
  return (
    <View style={[styles.flexTable]}>
      <TableHeader />
      <TableData
        items={items}
        material_schedule={material_schedule}
        mo_details={mo_details}
      />
    </View>
  );
};

const TableHeader = ({ items }) => {
  return (
    <View style={[styles.flexTableHeader, styles.flex]}>
      <View style={[styles.flexTableTd, styles.columnSNo]}>
        <Text>S.No</Text>
      </View>

      <View style={[styles.flexTableTd, styles.columnDescription]}>
        <Text>Description</Text>
      </View>

      <View style={[styles.flexTableTd, styles.columnQty]}>
        <Text style={styles.textRight}>Qty</Text>
      </View>

      <View style={[styles.flexTableTd, styles.columnQty]}>
        {/* used to be columnRate */}
        <Text style={styles.textRight}>Output</Text>
      </View>

      <View style={[styles.flexTableTd, styles.textRight]}>
        <Text>Average Cost</Text>
      </View>

      <View style={[styles.flexTableTd, styles.textRight]}>
        <Text>Tax</Text>
      </View>

      {/* Amount */}
      <View
        style={[styles.flexTableTd, styles.borderRightZero, styles.textRight]}
      >
        <Text>Total Cost</Text>
        <Text>(INR)</Text>
      </View>
    </View>
  );
};

const TableData = ({ items, material_schedule, mo_details }) => {
  return (
    <>
      {items.map(
        (
          {
            display,
            id,
            quantity,
            weight,
            uom,
            rate,
            tax,
            amount,
            discount,
            packtype,
            sNo,
            tax_code,
            sku_id,
          },
          i
        ) => {
          const currMaterial = material_schedule?.find((el) => el?.id === id);

          return (
            <View
              key={id}
              style={{
                flexDirection: "row",
                borderBottomWidth: 1,
                borderBottomColor: "lightgrey",
                backgroundColor: i % 2 ? "white" : "whitesmoke",
              }}
            >
              {/* S.No */}
              <View style={[styles.flexTableTd, styles.columnSNo]}>
                <Text>{sNo}</Text>
              </View>

              {/* Description */}
              <View
                style={[
                  styles.flexTableTd,
                  styles.flexCol,
                  styles.columnDescription,
                ]}
              >
                <Text style={styles.bold}>{display}</Text>
                <Text style={styles.italic}>{packtype}</Text>
              </View>

              {/* Qty */}
              <View style={[styles.flexTableTd, styles.columnQty]}>
                <Text style={styles.textRight}>
                  {currMaterial?.material_quantity} {currMaterial?.uom}
                </Text>
              </View>

              {/* Output */}
              <View style={[styles.flexTableTd, styles.columnQty]}>
                <Text style={styles.textRight}>
                  {/* {material_schedule[i]?.material_quantity /
                    weight
                  } Pc */}
                  {mo_details?.total_quantity} {mo_details?.uom}
                </Text>
              </View>

              {/* Average Cost */}
              <View style={[styles.flexTableTd, styles.textRight]}>
                <Text>{commaSeparatedRupees(currMaterial?.avg_cost)} INR</Text>
              </View>

              {/* Tax */}
              <View style={[styles.flexTableTd, styles.textRight]}>
                <Text>{currMaterial?.tax}%</Text>
              </View>

              {/* Amount */}
              <View
                style={[
                  styles.flexTableTd,
                  styles.borderRightZero,
                  styles.textRight,
                ]}
              >
                <Text>
                  {commaSeparatedRupees(currMaterial?.total_cost)} INR
                </Text>
              </View>
            </View>
          );
        }
      )}
    </>
  );
};

const CommentsAndBreakUp = ({
  moDetails: { comments, total_amount, transport_cost },
  items = [],
}) => {
  let _totalTax = 0,
    _subTotal = 0;

  items.forEach(({ tax, quantity, rate, amount, uom }) => {
    if (["g", "ml"].includes(uom)) quantity = quantity / 100;
    let _prod = quantity * rate;
    _subTotal += _prod;
    _totalTax += amount - _prod;
  });

  return (
    <>
      <View style={[styles.flex, { justifyContent: "space-between" }]}>
        <View style={{ marginTop: 5, width: "60%" }}>
          <FromToText heading="Comments / Special Instructions">
            <View style={styles.flexCol}>
              <Text>- Please send two copies of your invoice.</Text>
              <Text>
                - Enter this order in accordance with the prices, terms,
                delivery method, and specifications listed above.
              </Text>
              <Text>
                - Please notify us immediately if you are unable to ship as
                specified.
              </Text>

              {/* {comments && ( */}
              <>
                <View
                  style={{
                    height: 1,
                    backgroundColor: "lightgrey",
                    marginBottom: 2,
                  }}
                />
                <Text>- {comments}</Text>
              </>
              {/* )} */}
            </View>
          </FromToText>
        </View>
      </View>
    </>
  );
};

const LabourSchedule = ({ data }) => {
  return (
    <>
      <Text style={{ marginBottom: -8, marginTop: 10 }}>Resource Schedule</Text>
      <View style={[styles.flexTable]}>
        <LabourTableHeader />
        <LabourTableData items={data} />
      </View>
    </>
  );
};

const LabourTableHeader = ({ items }) => {
  return (
    <View style={[styles.flexTableHeader, styles.flex]}>
      <View style={[styles.flexTableTd, styles.columnSNo]}>
        <Text>S.No</Text>
      </View>

      <View style={[styles.flexTableTd, styles.columnResourceName]}>
        <Text>Resource Name</Text>
      </View>

      {/* HSN */}
      <View style={[styles.flexTableTd, styles.columnTaskType]}>
        <Text>Task Name</Text>
      </View>

      {/* Qty */}
      <View style={[styles.flexTableTd]}>
        <Text style={styles.textRight}>Cost Required (Estd.)</Text>
      </View>

      {/* Rate */}
      <View style={[styles.flexTableTd]}>
        <Text style={styles.textRight}>Time Required (Estd.)</Text>
      </View>

      <View style={styles.flexTableTd}>
        <Text style={styles.textRight}>Status</Text>
      </View>
    </View>
  );
};

const LabourTableData = ({ items }) => {
  return (
    <>
      {items.map(({ id, est_time, est_cost, name, resource, status }, i) => {
        return (
          <View
            key={id}
            style={{
              flexDirection: "row",
              borderBottomWidth: 1,
              borderBottomColor: "lightgrey",
              backgroundColor: i % 2 ? "white" : "whitesmoke",
            }}
          >
            <View style={[styles.flexTableTd, styles.columnSNo]}>
              <Text>{i + 1}</Text>
            </View>

            <View
              style={[
                styles.flexTableTd,
                styles.flexCol,
                styles.columnResourceName,
              ]}
            >
              <Text style={styles.bold}>{resource}</Text>
            </View>

            <View style={[styles.flexTableTd, styles.columnTaskType]}>
              <Text> {name} </Text>
            </View>

            <View style={[styles.flexTableTd]}>
              <Text style={styles.textRight}>{est_cost} INR</Text>
            </View>

            <View style={[styles.flexTableTd]}>
              <Text style={styles.textRight}>
                {minutesToDuration(est_time)}
              </Text>
            </View>

            <View style={styles.flexTableTd}>
              <Text style={styles.textRight}>{status}</Text>
            </View>
          </View>
        );
      })}
    </>
  );
};

const ClosingNotes = () => {
  return (
    <>
      <View
        style={[
          styles.flex,
          styles.justifyCenter,
          {
            marginTop: 30,
            borderTopWidth: 1,
            borderTopColor: "lightgrey",
            paddingTop: 3,
          },
        ]}
      >
        <Text>This is a Computer Generated Invoice</Text>
      </View>
    </>
  );
};

export default MakeOrderInvoice;
