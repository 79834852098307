import React, { useEffect, useState } from "react";
import { Table, Tooltip, Button, Modal, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import axios from "axios";
import { inventoryStatusIcon } from "../../../common/components/statusIcons";
import CreateOrUpdate from "../partners.createOrUpdateModal";
import useTableSearch from "../../../common/hooks/useTableSearch";
import { isEmpty, hasList } from "../../../common/functions/validateInputs";
import getAddress from "../../../common/functions/getAddress";
import { connect } from "react-redux";
import { getList } from "../../../actions/dataActions";

const SupplierMain = ({ getSupplierType, supplierType }) => {
  const [data, setData] = useState([]);

  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const [showCuModal, setShowCuModal] = useState(false);

  useEffect(() => {
    listAPICall();
    getSupplierType();
  }, []);

  const listAPICall = async () => {
    try {
      const { data } = await axios.get(
        `/api/partners/partner/list?role=supplier`
      );
      setData(data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const columns = [
    {
      title: "Supplier ID",
      dataIndex: "id",
      width: 50,
      ...useTableSearch("id"),
    },
    {
      title: "Supplier Name",
      dataIndex: "name",
      ...useTableSearch("name"),
      render: (value) => <b>{value}</b>,
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Addresses",
      dataIndex: "addresses",
      render: (addrArray, record, index) => {
        const { id, address1, address2, city, state } = addrArray[0] || "";
        return (
          <div>
            {id && getAddress({ address1, address2, city, state })}
            {addrArray?.length > 1 && ` +${addrArray.length - 1}`}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: "Active", value: "active" },
        { text: "Inactive", value: "inactive" },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (value) => inventoryStatusIcon(value),
    },
    {
      title: "Actions",
      render: (value, record) => {
        return (
          <Tooltip title="Edit">
            <Button
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                setSelectedSupplier(record);
                setShowCuModal(true);
              }}
              danger
            />
          </Tooltip>
        );
      },
    },
  ];

  const createOrUpdateHandler = async (supplierObject, isUpdate = false) => {
    supplierObject = { ...supplierObject, role: "supplier" };
    const isEditMode = selectedSupplier?.id;
    try {
      const url = `/api/partners/partner/${isEditMode ? "edit" : "create"}`;
      if (isEditMode) supplierObject["id"] = selectedSupplier.id;
      await axios.post(url, supplierObject);
      setShowCuModal(false);
      message.success("Customer created successfully");
      await listAPICall();
    } catch (error) {
      message.error(`${error.message} - ${error.response?.data?.message}`);
    }
  };

  const cancelHandler = () => {
    setShowCuModal(false);
    setSelectedSupplier(null);
  };

  return (
    <>
      <div className="flex justify-between mb-1">
        <div></div>
        <Button type="primary" onClick={() => setShowCuModal(true)}>
          + New Supplier{" "}
        </Button>
      </div>
      <Table columns={columns} dataSource={data} />

      <Modal
        destroyOnClose
        title={
          selectedSupplier?.id
            ? "Update supplier Details"
            : "Create a new supplier"
        }
        footer={null}
        style={{ top: 2 }}
        visible={showCuModal}
        onCancel={cancelHandler}
        width={"100%"}
      >
        {showCuModal && (
          <CreateOrUpdate
            partnerRole="Supplier"
            selectedPartner={selectedSupplier}
            onOk={createOrUpdateHandler}
            onCancel={cancelHandler}
          />
        )}
      </Modal>
    </>
  );
};

const mapState = (state) => {
  return {
    supplierType: state.data.supplierType,
  };
};

const mapDispatch = {
  getSupplierType: () => getList("supplierType"),
};

export default connect(mapState, mapDispatch)(SupplierMain);
