import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { PDFViewer, Font } from "@react-pdf/renderer";

import PurchaseOrderInvoice from "./pdf/bills/purchaseOrderInvoice";
import SalesOrderInvoice from "./pdf/bills/salesOrderInvoice";
import SalesOrderInvoice2 from "./pdf/bills/salesOrderInvoice/salesOrderInvoice2";
import MakeOrderInvoice from "./pdf/bills/makeOrderInvoice";
import { connect } from "react-redux";

Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: "Lato Italic",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

Font.registerHyphenationCallback((word) => [word]);

const INVOICE_MAPPING = {
  purchase: "po",
  sales: "so",
  make: "mo",
};

const Invoice = ({ organizationId }) => {
  const { type, orderId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState(null);

  const [error, setError] = useState(null);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.title =
      type?.charAt(0).toUpperCase() +
      type.slice(1, type.length) +
      " Order Invoice - Invento";
    getInvoiceData();
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const getInvoiceData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const { data } = await axios.get(
        `/api/invoice/${INVOICE_MAPPING[type]}?id=${orderId}`
      );

      // const data = sampleData;
      console.log(data, "this is data")

      const _data = {
        ...data,
        items: data.items
          // .slice(0, 7)
          .map((el, index) => ({ ...el, sNo: index + 1 })),
      };
      setInvoiceData(_data);
      setIsLoading(false);
    } catch (error) {
      window.alert(error.message);
      setError(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>Error: {JSON.stringify(error)} </div>
      ) : (
        <InvoiceWrapper organizationId={organizationId} type={type} data={invoiceData} />
      )}
    </>
  );
};

const InvoiceWrapper = ({ organizationId, type, data }) => {
  
  const docReturner = () => {
    switch (type) {
      case "purchase":
        return <PurchaseOrderInvoice data={data} />;
      case "sales":
        return organizationId[0]._id === "621346f29ad2ff6d4fecb6b2" ? (
          <SalesOrderInvoice2 data={data} />
        ) : (
          <SalesOrderInvoice data={data} />
        );
      case "make":
        return <MakeOrderInvoice data={data} />;
      default:
        break;
    }
  };

  return (
    <PDFViewer
      style={{
        width: "100%",
        height: "100vh",
      }}
    >
      {docReturner()}
    </PDFViewer>
  );
};

const mapStateToProps = (state) => {
  return {
    organizationId: state.auth?.user.organizations,
  };
};

export default connect(mapStateToProps)(Invoice);
